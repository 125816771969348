import React from "react";
import transition from "../transition";
import { Container } from "react-bootstrap";
import Footer from "../components/Footer/Footer";
import { CustomHeader } from "../components/Hero/styled";
import { CustomH2 } from "../components/Main/styled";

const Certificates = () => {
  return (
    <>
      <Container className="mt-5 gap-3 d-flex flex-column">
        <CustomHeader className="mb-5">Certificates</CustomHeader>
        <div className="d-flex flex-column justify-content-center align-items-center flex-md-row">
          <div className="relative d-flex rounded w-100">
            <img
              className="img-fluid"
              src="https://logowik.com/content/uploads/images/udemy-new-20212512.jpg"
              alt=""
            ></img>
          </div>
          <div className="w-100 d-flex flex-column justify-content-center px-md-4">
            <h3
              style={{ fontWeight: 500, fontSize: 18, letterSpacing: "-.5px" }}
            >
              (2023)
            </h3>
            <CustomH2>
              Udemy Understanding TypeScript - 2023 Edition Certificate
            </CustomH2>
          </div>
        </div>
        <hr></hr>
        <div className="d-flex flex-column justify-content-center align-items-center flex-md-row">
          <div className="relative d-flex rounded w-100">
            <img
              className="img-fluid"
              src="https://ffo3gv1cf3ir.merlincdn.net/SiteAssets/Hakkimizda/genel-bakis/logolarimiz/TURKCELL_YATAY_ERKEK_LOGO.jpg"
              alt=""
            ></img>
          </div>
          <div className="w-100 d-flex flex-column justify-content-center px-md-4">
            <h3
              style={{ fontWeight: 500, fontSize: 18, letterSpacing: "-.5px" }}
            >
              (2023)
            </h3>
            <CustomH2>
              Turkcell Geleceği Yazanlar React 101, 201, 301 / 2023
            </CustomH2>
          </div>
        </div>
        <hr></hr>
        <div className="d-flex flex-column justify-content-center align-items-center  flex-md-row">
          <div className="justify-content-center d-flex rounded w-100">
            <img
              className="img-fluid"
              src="https://cdn1.iconfinder.com/data/icons/logos-brands-in-colors/1123/Harvard_University_logo-512.png"
              alt=""
            ></img>
          </div>
          <div className="w-100 d-flex flex-column justify-content-center px-md-4">
            <h3
              style={{ fontWeight: 500, fontSize: 18, letterSpacing: "-.5px" }}
            >
              (2022)
            </h3>
            <CustomH2>
              Harvard College CS50 Introduction to Computer Science Certificate
            </CustomH2>
          </div>
        </div>
        <hr></hr>
        <div className="d-flex flex-column justify-content-center align-items-center  flex-md-row">
        <div className="relative d-flex rounded w-100">
            <img
              className="img-fluid"
              src="https://img-kariyer.mncdn.com/sirius/images/og-image.jpg"
              alt=""
            ></img>
          </div>
          <div className="w-100 d-flex flex-column justify-content-center px-md-4">
            <h3
              style={{ fontWeight: 500, fontSize: 18, letterSpacing: "-.5px" }}
            >
              (2022)
            </h3>
            <CustomH2>
              Kariyer.Net: Angular Bootcamp (Front-end) Certificate
            </CustomH2>
          </div>
        </div>
        <hr></hr>
        <div className="d-flex flex-column justify-content-center align-items-center  flex-md-row">
        <div className=" justify-content-center d-flex rounded w-100">
            <img
              className="img-fluid"
              src="https://i0.wp.com/softwareengineeringdaily.com/wp-content/uploads/2019/12/freecodecamp-vector-logo.png?fit=900%2C500"
              alt=""
            ></img>
          </div>
          <div className="w-100 d-flex flex-column justify-content-center px-md-4">
            <h3
              style={{ fontWeight: 500, fontSize: 18, letterSpacing: "-.5px" }}
            >
              (2022)
            </h3>
            <CustomH2>
              Freecodecamp Front End Development Libraries / JavaScript Algorithms and Data Structures / Responsive Web Design
            </CustomH2>
          </div>
        </div>
      </Container>
      <hr></hr>
      <Footer />
    </>
  );
};

export default transition(Certificates);
