import React from 'react'
import { Container, Button } from 'react-bootstrap'
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos';
import { CustomH2, CustomImage, ImageWrapper } from './styled';

const Main = () => {
  AOS.init();
  return (
    <section className='mt-3'>
    <Container>
      <div className='d-flex'>
        <div className='w-50'>
          <h3 style={{fontWeight: 500, fontSize: 18, letterSpacing: "-.5px"}}>(Selected work)</h3>
        </div>
        <div className='justify-content-end d-flex w-50'>
          <h3 style={{fontWeight: 500, fontSize: 18, letterSpacing: "-.5px"}}>Discover ↓</h3>
        </div>
      </div>
      <div>
      <ImageWrapper data-aos="fade-up" data-aos-duration="1500">
          <CustomImage alt='case-2' src='https://cdn.discordapp.com/attachments/293046796488278017/1144281328003592362/fIbhoiKKRyFSlQOJsk5v5HkBWFM_1.png'></CustomImage>
        </ImageWrapper> 
      </div>
      <div className='mt-4' style={{padding: "0 0 180px"}}>
        <div className='w-100 d-flex justify-content-between align-content-between'>
          <h3 style={{fontWeight: 500, fontSize: 18, letterSpacing: "-.5px"}}>Kloud+®</h3>
          <h3 className='d-none d-md-flex' style={{fontWeight: 500, fontSize: 18, letterSpacing: "-.5px"}}>Ecommerce website for the Turkcell Geleceği Yazanlar®.</h3>
          <h3 style={{fontWeight: 500, fontSize: 18, letterSpacing: "-.5px"}}>(2023)</h3>
        </div>
      </div>
      <div>
        <div className='d-flex flex-column flex-md-row' style={{padding: "0 0 180px"}}>
          <div className='w-50'>
          <h3 style={{fontWeight: 500, fontSize: 18, letterSpacing: "-.5px"}}>(001)</h3>
          </div>
          <div style={{gap: "2rem", display: "flex", position: "relative", flexDirection: "column", width: "90%"}}>
            <CustomH2>As a frontend developer who loves crafting immersive online experiences that help businesses and organizations worldwide engage with their users and expand their online presence.</CustomH2>
            <CustomH2>I collaborate directly with clients or contribute my expertise to agencies and studios globally to create top-notch web projects.</CustomH2>
          </div>
        </div>
      </div>
      <div>
      <ImageWrapper data-aos="fade-up" data-aos-duration="1500">
          <CustomImage alt='case-2' src='https://cdn.discordapp.com/attachments/293046796488278017/1144288860365389964/SK5CGysIoZhXA1fKmCTQ8e4PF2o.png'></CustomImage>
        </ImageWrapper> 
      </div>
      <div className='mt-4' style={{padding: "0 0 50px"}}>
        <div className='w-100 d-flex justify-content-between align-content-between'>
          <h3 style={{fontWeight: 500, fontSize: 18, letterSpacing: "-.5px"}}>Bl∞g®</h3>
          <h3 className='d-none d-md-flex' style={{fontWeight: 500, fontSize: 18, letterSpacing: "-.5px"}}>Personal blog website for the Turkcell Geleceği Yazanlar®.</h3>
          <h3 style={{fontWeight: 500, fontSize: 18, letterSpacing: "-.5px"}}>(2023)</h3>
        </div>
      </div>
      <ImageWrapper data-aos="fade-up" data-aos-duration="1500">
          <CustomImage alt='case-2' src='https://cdn.discordapp.com/attachments/430340733644570638/1144291667436916787/mlifyeQJOm073H1hPXEKXjL7WU.png'></CustomImage>
        </ImageWrapper>
        <div className='mt-4' style={{padding: "0 0 180px"}}>
        <div className='w-100 d-flex justify-content-between align-content-between'>
          <h3 style={{fontWeight: 500, fontSize: 18, letterSpacing: "-.5px"}}>Monito®</h3>
          <h3 className='d-none d-md-flex' style={{fontWeight: 500, fontSize: 18, letterSpacing: "-.5px"}}>Ecommerce website for the Turkcell Geleceği Yazanlar®.</h3>
          <h3 style={{fontWeight: 500, fontSize: 18, letterSpacing: "-.5px"}}>(2023)</h3>
        </div>
      </div>
      <div>
        <div className='d-flex flex-column flex-md-row' style={{padding: "0 0 180px"}}>
          <div className='w-50'>
          <h3 style={{fontWeight: 500, fontSize: 18, letterSpacing: "-.5px"}}>(002)</h3>
          </div>
          <div style={{gap: "2rem", display: "flex", position: "relative", flexDirection: "column", width: "90%"}}>
            <CustomH2>Looking to grow your business through the power of great digital design? </CustomH2>
            <CustomH2>Reach out to get the conversation started about your digital design needs. </CustomH2>
            <Button data-aos="fade-up" data-aos-duration="1700" size='lg' href='mailto:mucahit.kuk@gmail.com' variant="dark" style={{borderRadius: 70, width: 250}} className='p-2.5 text-black bg-transparent'>
          <span style={{letterSpacing: "-0.5px"}}>Let's connect</span>
        </Button>
          </div>
        </div>
      </div>
    </Container>
    </section>
  )
}

export default Main