import React from 'react'
import transition from '../transition'
import { Container } from 'react-bootstrap'


const About = () => {
  return (
    <Container>
      <h1>Work in Progress..</h1>
    </Container>
  )
}

export default transition(About)