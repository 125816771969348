import React from "react";
import transition from "../transition";
import Hero from "../components/Hero/Hero";
import Main from "../components/Main/Main";
import Footer from "../components/Footer/Footer";
import { ParallaxText } from "../components/Parallex/Parallex";


const Home = () => {
  return (
    <>
      <Hero />
      <Main />
      <ParallaxText baseVelocity={-1}>HTML - CSS - REACT - JAVASCRIPT - TYPESCRIPT - SASS/LESS - MONGODB - POSTRESQL - NODE/EXPRESS - REDUX -</ParallaxText>
      <Footer />
    </>
  );
}


export default transition(Home)