import React from "react";
import "./App.css";
import Header from "./components/Nav/Nav";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Home from "./pages/Home"
import Projects from "./pages/Projects";
import About from "./pages/About";
import Certificates from "./pages/Certificates";


const App = () => {
  const location = useLocation();

  return (
    <>
      <Header />
      <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route index element={<Home />}/>
        <Route path="/projects" element={<Projects />} />
        <Route path="/about" element={<About />} />
        <Route path="/certificates" element={<Certificates />} />
      </Routes>
      </AnimatePresence>
    </>
  );
};

export default App;
