import styled from "styled-components";

export const ImageWrapper = styled.div`
  margin-top: 2rem;
  align-content: center;
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  height: 85vh;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
  will-change: transform;
  border-radius: 10px;
  @media screen and (max-width: 768px) {
   height: 450px;
  }
`;


export const CustomImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-position: center;
  object-fit: cover;
  image-rendering: auto;
  transform: none;
  transition: transform 0.5s ease-in-out;
  &:hover {
    transform: scale(1.0333); 
    cursor: pointer;
  }
`;


export const CustomH2 = styled.h2`
  font-weight: 500;
  line-height: 44px;
  font-size: 35px;
  letter-spacing: -1.3px;
  @media screen and (max-width: 1200px) {
   font-size: 31px;
   line-height: 33px;
  }
  @media screen and (max-width: 992px) {
   
  }
  @media screen and (max-width: 768px) {
  
  }
  @media screen and (max-width: 568px) {
 
  }
`