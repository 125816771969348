import styled from "styled-components";

export const CustomWrapper = styled.div`
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0px;
  height: min-content;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0 0 30px;
  position: relative;
  width: 100%;
`;

export const CustomHeader = styled.h1`
  font-weight: 700;
  font-size: 102px;
  line-height: 0.9em;
  letter-spacing: -5px;
  @media screen and (max-width: 1200px) {
    font-size: 70px;
  }
  @media screen and (max-width: 992px) {
    font-size: 60px;
  }
  @media screen and (max-width: 768px) {
    font-size: 45px;
    letter-spacing: -3px;
  }
  @media screen and (max-width: 568px) {
    font-size: 30px;
    letter-spacing: -2.5px;
  }
`;


export const TextWrapper2 = styled.div`
  width: 80%;
  @media screen and (max-width: 568px) {
    width: auto !important;
  }
`

export const CustomText2 = styled.h2`
  font-weight: 500; 
  font-size: 40; 
  line-height: 1.2;
  letter-spacing: -2.5px;
  @media screen and (max-width: 1200px) {
    font-size: 31px;
  }
  @media screen and (max-width: 992px) {
    font-size: 28px;
  }
  @media screen and (max-width: 768px) {
   font-size: 25px;
   letter-spacing: -1px;
   width: 75%;
  }
  @media screen and (max-width: 568px) {
   font-size: 20px;
   letter-spacing: -1.5px;
  }
`