import React from 'react'
import { Container } from 'react-bootstrap'
import { CustomHeader } from '../Hero/styled'
import Signature from '../Signature'
import 'aos/dist/aos.css'; // Import AOS styles

const Footer = () => {
  return (
    <footer style={{padding: "0 0 30px"}} className='mt-5'>
      <Container className='d-md-block d-flex flex-column align-items-center'>
      <CustomHeader>MUCAHIT KUK</CustomHeader>
      <CustomHeader>FRONTEND DEVELOPER</CustomHeader>
      <div className='d-flex flex-column align-items-center gap-3 flex-md-row justify-content-between mt-4'>
      <div className='d-flex' style={{gap: "2rem"}}>
        <a href='https://www.linkedin.com/in/mucahitkuk/' target='_blank' rel='noreferrer' style={{fontWeight: 500, fontSize: 18, lineHeight: 1, letterSpacing: "-0.5px", textDecoration: "none", color: "black"}}>LinkedIn</a>
        <a href='https://open.spotify.com/user/11134272821' target='_blank' rel='noreferrer' style={{fontWeight: 500, fontSize: 18, lineHeight: 1, letterSpacing: "-0.5px", textDecoration: "none", color: "black"}}>Spotify</a>
        <a href='https://github.com/mchtkuk' target='_blank' rel='noreferrer' style={{fontWeight: 500, fontSize: 18, lineHeight: 1, letterSpacing: "-0.5px", textDecoration: "none", color: "black"}}>Github</a>
      </div>
      <div>
      <a href="#top" style={{fontWeight: 500, fontSize: 18, lineHeight: 1, letterSpacing: "-0.5px", textDecoration: "none", color: "black"}} className="back-to-top-link">Back to top ↑</a>
      </div>
      <div>
        <h2 style={{fontWeight: 500, fontSize: 18, lineHeight: 1, letterSpacing: "-0.5px", textDecoration: "none", color: "black"}}>Copyright©Mucahitkuk</h2>
      </div>
      </div>
      <div className='d-flex justify-content-center mt-4'>
        <Signature data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300" />
      </div>
      <div>
        <h6>Disclaimer: This site is still work in progress.</h6>
      </div>
      </Container>
    </footer>
  )
}

export default Footer