import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const SvgContainer = styled.div`
  display: flex;
`;
export const StyledNavLink = styled(Nav.Link)`
  font-weight: 500;
`;

export const StyledLink = styled(Link)`
  display: inline-block;
  position: relative;
  color: black;
  text-decoration: none;
  font-weight: 500;
::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: black;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
`;
